<template>
    <TemplateBase v-if="entry">
        <template #hero>
            <LocationHero :hero="hero" />
        </template>

        <FlexibleSections :sections="entry.flexibleSections" />
    </TemplateBase>
</template>

<script setup>
import { useLocationQuery } from '~/composables/villus/location/query.ts';
import { useCraftEntrySetup } from '~/composables/useCraftEntrySetup';
import useColorTheme from '~/composables/useColorTheme';

const { data } = await useLocationQuery();
const entry = useCraftEntrySetup(data);

const hero = {
    title: entry?.heroTitle || entry?.title,
    description: entry?.description,
    images: entry?.featuredImages ? entry?.featuredImages : null,
    section: entry?.sectionHandle,
    location: entry?.location
};

useColorTheme({
    inverse: false,
    switchOnScroll: true
});

useHead({
    bodyAttrs: {
        'is-location': true
    }
});
</script>
