<template>
    <BaseHero
        class="location-hero"
    >
        <template #title>{{ hero.title }}</template>
        <template #default>
            <LocationLogo :location="hero.location" class="location-hero__logo" />
            <div class="location-hero__description">
                {{ hero.description }}
            </div>
            <MetaLine direction="vertical" class="location-hero__scroll-intent">
                Scroll
            </MetaLine>
        </template>
        <template #image>
            <BaseImage
                :src="image?.w320"
                :src-tiny="image?.w32"
                :srcset="`
                    ${image?.w320} 320w,
                    ${image?.w768} 768w,
                    ${image?.w980} 980w,
                    ${image?.w1260} 1260w
                `"
                sizes="
                    (max-width: 380px) 320px,
                    768px
                "
                :height="image?.height"
                :width="image?.width"
                :alt="image?.alt"
            />
        </template>
    </BaseHero>
</template>

<script setup>
const props = defineProps({
    hero: {
        type: Object,
        required: true,
        default: () => {}
    }
});

const { image, start: startFlipper, stop: stopFlipper } = useFlipper(props?.hero?.images, { interval: 3000 });
startFlipper();

onUnmounted(() => {
    stopFlipper();
});
</script>

<style lang="less" src="./LocationHero.less" />
